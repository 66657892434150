import { Button, SimpleGrid, Space, PasswordInput } from '@mantine/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { $TsFixMe } from '../../../module';
import { MutationCreateSessionInputSchema } from '../../graphql/inputs.generated';
import { useCreateSession } from '../../hooks/useSession';
import EmailField from './Fields/EmailField';

type AuditLoginFormProps = {
  onSuccess?: Parameters<typeof useCreateSession>[0];
  redirect?: boolean;
  initialValues?: { [key: string]: $TsFixMe };
};

type FormValues = {
  email: string;
  code: string;
};

const AuditLoginForm = ({
  onSuccess = () => {},
  redirect = true,
  initialValues = {},
}: AuditLoginFormProps) => {
  const [createSession] = useCreateSession(onSuccess, redirect);

  const {
    handleSubmit,
    register,
    getValues,
    control,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>();

  const onSubmit = handleSubmit(async (data) => {
    return createSession({ variables: { input: MutationCreateSessionInputSchema().parse(data) } });
  });

  return (
    <form onSubmit={onSubmit}>
      <SimpleGrid cols={1}>
        <EmailField<FormValues> key="email" name="email" label="Email" control={control} />
        <PasswordInput
          key="code"
          {...register('code', { required: 'Required' })}
          label="Password"
          error={errors.code?.message}
        />

        <Button type="submit" color="dark" loading={isSubmitting}>
          Login
        </Button>
      </SimpleGrid>
      <Space h="xl" />
    </form>
  );
};

export default AuditLoginForm;
