import { Button, SimpleGrid } from '@mantine/core';
import React from 'react';
import { Google } from '@emotion-icons/bootstrap/Google';

const LoginForm = () => {
  return (
    <form>
      <SimpleGrid cols={1}>
        <Button
          leftIcon={<Google width={16} height={16} />}
          variant="default"
          color="gray"
          component="a"
          href="/api/oauth/google"
          size="lg"
        >
          Continue with Google
        </Button>
      </SimpleGrid>
    </form>
  );
};

export default LoginForm;
