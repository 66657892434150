import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import formatStringByPattern from 'format-string-by-pattern';
import { isEmpty, isNil, isUndefined, memoize } from 'lodash';
import numeral from 'numeral';
import { SyntheticEvent } from 'react';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import isURL from 'validator/lib/isURL';
import { z } from 'zod';
import { $TsFixMe } from '../../module';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const COMMA_TOKEN = [','];

export const validateEmail = (value: string) => {
  if (value) {
    return isEmail(value) ? undefined : 'Invalid email';
  }

  return undefined;
};

export const validateSelectOptions =
  (selectOptions?: { label: string; value?: string | null }[]) => (value: $TsFixMe) =>
    (selectOptions || []).some((option) => option.value === value)
      ? undefined
      : 'Invalid selection';

export const validateUrl = (value: string) => {
  if (value) {
    return isURL(value, { require_protocol: true }) ? undefined : 'Invalid url';
  }
  return undefined;
};

export const emailFormatterParser = {
  validate: validateEmail,
};

export const feinFormatterParser = {
  finalFormFormat: (value: string) => {
    if (value) {
      const onlyNumbers = String(value).replace(/[^\d]/g, '');

      return formatStringByPattern('XX-XXXXXXX', onlyNumbers);
    }

    return value;
  },
  finalFormParse: (value?: number | string | null) =>
    value ? String(value).replace(/-/g, '') : value,
  maxLength: 10,
  validate: (value?: number | string | null) => {
    if (value && feinFormatterParser.finalFormParse(value)) {
      // @ts-expect-error
      return isLength(feinFormatterParser.finalFormParse(value), { min: 9, max: 9 })
        ? undefined
        : 'Must be 9 characters';
    }

    return undefined;
  },
};

export const dollarFormatterParserMantine = {
  formatter: (value: string | number | undefined) =>
    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value: string | number | undefined) => {
    if (!isNil(value)) {
      if (typeof value === 'string') {
        const val = value.replace(/\$\s?|(,*)/g, '');
        return val ? val : '0';
      }

      const val = value.toString();
      return val ? val : '0';
    }

    return '0';
  },
  formParser: (value: string | number | undefined) =>
    !isNil(value) ? parseFloat(String(value)) : undefined,
};

export const numberFormatterParserMantine = {
  formFormatter: (value: string | number | undefined) =>
    !isNil(value) ? parseFloat(String(value)) : undefined,
  formParser: (value: string | number | undefined) =>
    !isNil(value) ? parseFloat(String(value)) : undefined,
};

export const numberFormatterParser = {
  finalFormFormat: (value: string | number | undefined) =>
    !isNil(value) ? parseFloat(String(value)) : undefined,
  finalFormParse: (value: string | number | undefined) =>
    !isNil(value) ? parseFloat(String(value)) : undefined,
};

export const numberAsStringFormatterParser = {
  finalFormFormat: (value: string | number | undefined) =>
    !isNil(value) ? String(value) : undefined,
  finalFormParse: (value: string | number | undefined | SyntheticEvent) => {
    // @ts-expect-error for now.
    const toValue = value?.target ? value.target.value : value;
    return !isNil(toValue) ? String(toValue) : undefined;
  },
};

export const dateFormatterParserTableMantine = {
  formFormatter: (value: any) => {
    return value ? dayjs(value).toDate() : null;
  },
  formParser: (value: any) => {
    return dayjs(value);
  },
};

export const percentFormatterParser = memoize((format = '0.00%') => {
  return {
    formatter: (value: string | number | undefined) => {
      if (isNil(value)) return '';
      if (!isNil(numeral(value).value())) {
        return `${numeral(value).format(format)}`;
      }
      return '';
    },
    parser: (value: string | number | undefined) => {
      if (!isNil(value)) {
        if (typeof value === 'string') {
          return parseFloat(value.replace('%', '')) / 100;
        }

        return parseFloat(value.toString());
      }

      return '';
    },
    precision: 2,
    finalFormParse: (value: string | number | undefined) =>
      !isNil(value) ? parseFloat(String(value)) : undefined,
  };
});

export const numeralDollarFormatter = (data: string | number) => numeral(data).format('$0,0');

export const fullWithInput = { width: '100%' };

export const formValidator =
  <T extends z.ZodType<$TsFixMe, $TsFixMe>>(schema: T) =>
  (values: $TsFixMe) => {
    try {
      schema.parse(values);
      return {};
    } catch (err) {
      return (err as z.ZodError).formErrors.fieldErrors;
    }
  };

export const mergeFormTableError = (
  key: string,
  valueItem: Record<string, any>,
  allValues: Record<string, any>,
  validate?: (value: any, allValues: any) => string | undefined
) => {
  if (validate) {
    const errorMessage = validate(valueItem[key], allValues);

    return errorMessage ? { [key]: errorMessage } : {};
  }

  return {};
};

export const validateFormTableRows = <T = any>(
  values: T[],
  validateRow: (valueItem: T) => Partial<Record<keyof T, string | undefined>>
) => {
  const errors = (values || []).reduce((all, valueItem) => {
    const errorItem = validateRow(valueItem);

    return [...all, isEmpty(errorItem) ? undefined : errorItem];
  }, [] as (Partial<Record<keyof T, string | undefined>> | undefined)[]);

  return errors.every(isUndefined) ? undefined : errors;
};

export type ValidateTableRowsSchema<T, X extends keyof T = any> = {
  validate: (valueItem: T) => ((value: any) => string | undefined) | undefined;
};

export type ValidateTableRowsSchemaObject<T> = Record<keyof T, ValidateTableRowsSchema<T>>;

export const validateTableRows = <T extends object>(
  values: T[],
  schema: ValidateTableRowsSchemaObject<T>
) => {
  return (values || []).map((valueItem) => {
    const keys = Object.keys(valueItem) as (keyof T)[];

    return keys.reduce((all, key: keyof T) => {
      return {
        ...all,
        [key]: schema[key]?.validate(valueItem)?.(valueItem[key]),
      };
    }, {} as Record<keyof T, string | undefined>);
  });
};
